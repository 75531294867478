import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';
// import AboutHighlights from './AboutHighlights';

export default function About() {

  const data = useStaticQuery(graphql`
    {
      cutout: file(relativePath: {eq: "sarah-darryl-dreyer--cropped.png"}) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
          )
        }
      }
      signature: file(relativePath: {eq: "white-paul.png"}) {
        id
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
          )
        }
      }
      background: file(relativePath: {eq: "giammarco-boscaro-380907-unsplash.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
    }
  `)

  // const cutoutProfile = getImage(data.cutout);
  // const signaturePng = getImage(data.signature);
  const aboutBG = getImage(data.background);
  const stackedAboutBG = [`linear-gradient(rgba(47,47,47,0.85),rgba(47,47,47,0.85))`, aboutBG];

  return (
    <BgImage className="about" image={stackedAboutBG} preserveStackingContext>
      {/* <div className="container">
        <AboutHighlights />
      </div>
      <div className="clear"></div> */}
      <div className="container">
        <div className="about--content">
          {/* About Intro */}
          <div className="about--content__item">
            <div className="about--content__wrapper">
              <div className="about--content__wrapper--inner">
                <div className="about--content__wrapper--title">
                  <h3>About Dreyer Law</h3>
                </div>
                <span>At Dreyer Law, we understand how serious injury and accident cases can impact your life. We have represented thousands of clients facing difficult personal and legal challenges. In every case, we work diligently to seek the most favorable result possible for our clients, while striving to surpass our clients' expectations for personal service.</span>
              </div>
            </div>
            <div className="about--content__signature">
              <div className="about--content__signature--wrapper">
                <div className="about--content__signature--frame">
                  {/* <GatsbyImage image={signaturePng} alt="Darryl & Sarah Dreyer" /> */}
                </div>
              </div>
            </div>
            <div className="about--content__caption">
              <div className="about--content__caption--inner">
                <div className="about--content__caption--title">
                  <h3>Darryl & Sarah Dreyer</h3>
                </div>
              </div>
            </div>
          </div>
          {/* Cutout photo */}
          <div className="about--content__image">
            <div className="about--content__image--inner">
              <div className="about--content__image--frame">
                {/* <GatsbyImage image={cutoutProfile} alt="Darryl & Sarah Dreyer" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BgImage>
  );
}
